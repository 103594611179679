.history-container {
    min-height: 100vh;
    background-color: var(--gray-2-color);
}

.history-card {
    margin-bottom: 15px;
    padding: 10px 20px;
}

.white { 
    background-color: var(--white-color);
}

.orange { 
    background-color: #fff2ee;
}

.history-card:first-child {
    margin-top: 15px;
}

.history-card:last-child {
    margin-bottom: 0;
}

.history-item-container {
    display: flex;
}

.history-detail-image-container {
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.history-detail-image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    background-size: cover;
    border-radius: 10px;
}

.history-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
}

.history-detail-name,
.history-detail-days {
    margin-bottom: 10px;
}

.history-detail-name {
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.history-detail-days {
    font-size: 10px;
}

.history-detail-item-status {
    display: flex;
    justify-content: space-between;
}

.history-detail-item,
.history-detail-status {
    font-size: 12px;
}

.rating {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 35px;
    margin-top: 0px;
}

#rating-icon {
    margin: 0 8px;
}


.filled {
    color: var(--orange-color);
}