.cart-container {
    padding: 20px;
}

.cart-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.cart-card img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
}

.cart-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
    border-bottom: 2px solid #e0e0e0;
    padding-top: 5px;
}

.cart-merchant-name,
.cart-items {
    height: 100%;
    color: var(--black-color);
    text-transform: capitalize;
}

.cart-merchant-name {
    font-size: 18px;
}

.cart-items {
    font-size: 14px;
}

.cart-detail-container {
    background-color: var(--gray-2-color);
    min-height: 100vh;
}

.cart-detail-main-content {
    /* padding: 20px 0 0 0; */
    display: flex;
    flex-direction: column;
}

.items-in-cart-title {
    border-bottom: 2px solid #e0e0e0;
    padding: 20px 0 0 10px;
    background-color: var(--white-color);
}

.items-in-cart-title span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: var(--black-color);
}

.items-in-cart-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    background-color: var(--white-color);
}

.items-in-cart {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--gray-2-color);
}

.items-in-cart:last-child {
    border-bottom: none;
}

.items-in-cart-icon-container {
    position: relative;
    top: 3px;
    margin-right: 7px;
}

.items-in-cart-name-container {
    width: 40%;
}

.items-in-cart-name {
    font-size: 12px;
    color: var(--black-color);
    font-weight: bold;
}

.items-in-cart-notes-title,
.items-in-cart-notes {
    font-size: 10px;
    color: var(--black-color);
}

.edit-notes {
    border: 1px solid var(--gray-1-color);
    padding: 2px;
    border-radius: 5px;
    font-size: 10px;
}

.edit-notes:focus {
    outline: none;
}

.items-in-cart-counted-container {
    width: 30%;
    text-align: center;
}

.items-in-cart-counted {
    font-size: 12px;
    margin: 0 5px;
}

.cart-detail-button-counter {
    border: 1px solid var(--black-color);
    background-color: transparent;
    color: var(--black-color);
    padding: 0 4px;
    border-radius: 0 5px;
}

.items-in-cart-price-container {
    width: 30%;
    text-align: right;
}

.items-in-cart-price {
    font-size: 12px;
}

.comment-input-container {
    display: flex;
    padding: 25px 10px;
    background-color: var(--white-color);
}

.comment-input-container input {
    width: 100%;
    height: 50px;
    padding: 0 7px;
    color: var(--black-color);
    border: 1px solid var(--gray-1-color);
    border-radius: 10px;
    font-size: 14px;
}

.comment-input-container input:focus {
    outline: none;
}

.bill-detail-wrapper {
    position: relative;
    width: 100%;
    padding-top: 10px;
}

.bill-detail-container {
    position: relative;
    background-image: url(../../assets/images/bill.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 310px;
    padding: 5% 40px 0 40px;
}

.bill-detail {
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 4%;
}

.bill-detail span {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--black-color);
    font-size: 14px;
}

.bill-detail-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 4px;
    color: #424242;
    text-transform: capitalize;
    font-size: 12px;
}

.bill-detail-content:last-child {
    margin-bottom: 0;
}

.red-color {
    color: #E50F0F;
}

.to-pay span {
    font-weight: bold;
}

@media screen and (max-width: 374px) { 
    
    .bill-detail {
        margin-bottom: 2%;
    }
    
    .bill-detail span {
        font-size: 11px;
    }

    .bill-detail-content {
        font-size: 10px;
        margin-bottom: 5px;
    }
}

/* @media screen and (max-width: 425px) {

    .items-in-cart-name {
        font-size: 16px;
        color: #424242;
    }
    
    .items-in-cart-notes-title,
    .items-in-cart-notes {
        font-size: 12px;
        color: #424242;
    }

    .items-in-cart-counted,
    .items-in-cart-price {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {

    .items-in-cart-name {
        font-size: 14px;
        color: #424242;
    }
    
    .items-in-cart-notes-title,
    .items-in-cart-notes {
        font-size: 10px;
        color: #424242;
    }

    .items-in-cart-counted,
    .items-in-cart-price {
        font-size: 12px;
    }
}

@media screen and (max-width: 320px) {

    .items-in-cart-name {
        font-size: 12px;
        color: #424242;
    }
    
    .items-in-cart-notes-title,
    .items-in-cart-notes {
        font-size: 8px;
        color: #424242;
    }

    .items-in-cart-counted,
    .items-in-cart-price {
        font-size: 10px;
    }
} */