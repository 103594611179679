.after-order-container {
    padding-top: 10px;
    min-height: 100vh;
    background-color: var(--gray-2-color);
}

.after-order-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    margin-bottom: 10px;
    background-color: var(--white-color);
    cursor: pointer;
}

.after-order-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.after-order-desc {
    font-size: 12px;
}

.after-order-card:last-child {
    margin-bottom: 0;
}

.after-order-status-container {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 280px) {
    .after-order-title {
        font-size: 13px;
    }

    .after-order-desc {
        font-size: 11px;
    }
}