#-white {
    fill: var(--white-color);
}

#-black {
    fill: var(--black-color);
}

#-orange {
    fill: var(--orange-color);
}

#-gray-1 {
    fill: var(--gray-1-color)
}

#-gray-2 {
    fill: var(--gray-2-color)
}

#-gray-3 {
    fill: var(--gray-3-color)
}

#home-small-icon {
    width: 20px;
}

#cart-small-icon {
    width: 24px;
    /* font-size: 50px; */
}

#order-medium-icon,
#confirm-medium-icon,
#cart-medium-icon {
    width: 40px;
    fill: var(--orange-color);
}

#cart-big-icon {
    width: 500px;
    fill: var(--gray-2-color);
}

#back-small-icon {
    width: 30px;
}

#calendar-small-icon {
    position: relative;
    top: 4px;
    margin: 0 10px 0 15px;
    width: 14px;
    fill: var(--orange-color);
}

#merchant-icon-info {
    height: 10px;
}

#bottomnav-icon {
    height: 18px;
}

#edit-small-icon {
    width: 14px;
    fill: var(--orange-color);
}

#star-border-medium-icon,
#star-medium-icon {
    width: 24px;
    margin: 0 7px;
}

#star-border-medium-icon {
    fill: var(--gray-1-color);
}

#star-medium-icon {
    fill: var(--orange-color);
}

#empty-cart-img {
    width: 100%;
}

#halal-logo {
    
}