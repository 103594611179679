.search-input-container {
    position: relative;
    display: flex;
    width: 100%;
}

.search-input {
    width: 100%;
    height: 50px;
    margin: 10px 10px;
    padding: 0 40px;
    font-size: 13px;
    border: none;
    border-radius: 10px;
}

.explore-search-box {
    border: none;
    border-radius: 0px;
    padding: 0 10px;
}

.merchant-detail-search {
    background-color: var(--gray-2-color);
    border: 1px solid var(--gray-1-color);
    color: var(--black-color);
    font-family: 'Roboto', sans-serif;
}

.search-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 23px;
    left: 20px;
    color: var(--gray-1-color);
    cursor: pointer;
}

.search-input:focus {
    outline: none;
}