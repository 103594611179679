.empty-explore-container, 
.empty-cart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    min-height: 100%;
}

.empty-cart-container {
    flex-direction: column;
}

.empty-cart-caption-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}

.empty-cart-caption-container span {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.empty-cart-caption-container button {
    padding: 7px 10px;
    background-color: var(--orange-color);
    color: var(--white-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}