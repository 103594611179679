.language-chooser-modal-container {
    height: 100%;
    width: 500px;
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width: 500px) {
    .language-chooser-modal-container{
        width: 100%;
    }
    .language-chooser-container  {
        width: 100%;
    }
}

.card-language-choosed {
    color: var(--white-color);
    background-color: var(--orange-color);
    
}

.card-language {
    color: var(--orange-color);
    background-color: var(--gray-3-color);
    
} 

.card-language:active {
    color: var(--gray-3-color);
    background-color: var(--orange-color);
}

.card-language, .card-language-choosed {
    margin-bottom: 20px;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.container-scroll-card {
    overflow-y: scroll;
    margin-bottom: 20px;
}

.container-card {
    padding: 20px;
}

.container-close-language {
    text-align: right;
    font-size: 20px;
}

.container-chooser-language {
    margin-top: 20;
    background-color: var(--white-color);
    height: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    top: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}