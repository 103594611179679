.receipt-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.receipt-container {
    width: 100%;
    box-shadow: 0 3px 16px var(--gray-1-color);
}

.receipt-header {
    display: flex;
    height: 40px;
    background-color: var(--orange-color);
    align-items: center;
    padding-left: 10px;
}

.order-code {
    color: var(--white-color);
    font-weight: bold;
}

.receipt-info-container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.receipt-info {
    width: 50%;
    margin-bottom: 20px;
    padding: 0 10px;
}

.receipt-client-title {
    font-size: 11px;
}

.receipt-client-content {
    font-size: 14px;
}

.order-items-container {
    border-bottom: 2px solid var(--gray-1-color);
    padding: 10px;
}

.order-items-text {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.receipt-order-detail-wrapper {
    
}

.receipt-order-detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 2px solid var(--gray-2-color);
}

.receipt-order-detail-container:last-child {
    border-bottom: none;
}

.receipt-item-name {
    font-size: 12px;
    font-weight: bold;
}

.receipt-item-notes-title,
.receipt-item-notes {
    font-size: 10px;
}

.receipt-item-counted, 
.receipt-item-price {
    font-size: 12px;
}

.note-to-merchant {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.note-to-merchant-title {
    font-size: 12px;
    color: var(--orange-color);
    font-weight: bold;
}

.note-to-merchant-content {
    font-size: 10px;
}

.receipt-price-detail-container {
    padding: 20px 10px;
}