/* .white-space {
    display: flex;
    height: 120px;
}

.topnav {
    position: fixed;
    top: 0;
    width: 1024px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    z-index: 999;
    padding-top: 30px;
    padding-bottom: 10px;
}

.topnav-title {
    margin-left: 30px;
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.topnav-cart-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.topnav-a {
    margin: 0 30px;
    -webkit-tap-highlight-color: transparent;
}

.topnav-icon {
    width: 30px;
}

@media screen and (max-width: 1024px) {
    .topnav {
        width: 100%;
    }
} */

/* .white-space {
    display: flex;
    height: 120px;
}

.topnav-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 120px;
    display: flex;
    background-color: #fff;
    z-index: 999;
}

.homepage {
    align-items: center;
    justify-content: space-between;
}

.homepage-topnav-left-side {
    display: flex;
    height: 100%;
    padding-left: 20px;
    align-items: center;
    justify-content: center;
}

.topnav-left-side {
    display: flex;
    width: 10%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-center-side {
    display: flex;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-title-text {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}

.topnav-right-side {
    display: flex;
    width: 10%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
}

.homepage-topnav-title-text {
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-transform: capitalize;
}

.topnav-icon {
    font-size: 34px;
    cursor: pointer;
}

.homepage-icon-margin {
    margin-right: 20px;
}

@media screen and (max-width: 1024px) {
    .topnav-container {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .topnav-container {
        height: 90px;
    }

    .white-space {
        height: 90px;
    }

    .topnav-left-side {
        width: 15%;
    }

    .topnav-center-side {
        width: 70%;
    }

    .topnav-title-text {
        font-size: 26px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .topnav-right-side {
        width: 15%;
    }

    .homepage-topnav-title-text {
        font-size: 28px;
    }

    .topnav-icon {
        font-size: 27px;
    }

    .homepage-icon-margin {
        margin-right: 35px;
    }
} */

.topnav-container {
    position: fixed;
    display: flex;
    top: 0;
    height: 60px;
    width: 500px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    z-index: 2;
}

.category-topnav-container {
    position: relative;
    display: flex;
    height: 60px;
    width: 500px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    z-index: 2;
}

.white-space {
    display: flex;
    height: 60px;
}

.relative-position {
    position: relative;
}

.homepage-topnav,
.receipt-topnav { 
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.homepage-topnav-title,
.receipt-topnav-title { 
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.homepage-cart-container {
    cursor: pointer;
}

.topnav-left-side,
.topnav-center-side,
.topnav-right-side {  
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.topnav-left-side {
    width: 15%;
    /* background-color: aqua; */
}

.none { 
    position: relative;
    top: -60px;
    display: flex;
    width: 70%;
    justify-content: flex-start;
    -webkit-animation: moveUp .2s ease-out forwards;
}

.topnav-center-side {
    position: relative;
    display: flex;
    width: 70%;
    justify-content: flex-start;
}

.merchant-detail-topnav-center-side {
    position: relative;
    display: flex;
    width: 70%;
    justify-content: flex-start;
    -webkit-animation: moveDown .2s ease-out forwards;
}

.topnav-right-side {
    width: 15%;
    /* background-color: antiquewhite; */
}

.topnav-title-text {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
}

.margin-left-10 {
    margin-left: 10px;
}

@keyframes moveDown
{ 
  from { 
      top: -60px;
    }

  to { 
      top: 0px;
    }
}

@keyframes moveUp
{ 
  from { 
      top: 0px;
    }

  to { 
      top: -60px;
    }
}

@media screen and (max-width: 500px) {
    .topnav-container {
        width: 100%;
    }
}
