.choose-payment-container {
    background-color: var(--gray-2-color);
    min-height: 100vh;
}
.product-bill-container {
    position: relative; 
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--white);
}
.product-bill {
    position: relative;
    background-color: white;
    height: 310px;
    padding: 5% 40px 0 40px;
}
.delivery-bill {
    position: relative;
    background-color: white;
    height: 200px;
    padding: 5% 40px 0 40px;
}
.product-bill-detail {
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 4%;
}
.product-bill-detail span {
    font-weight: bold;
    color: var(--black-color);
    font-size: 14px;
}
.product-bill-detail-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 4px;
    color: #424242;
    text-transform: capitalize;
    font-size: 12px;
}
.product-bill-detail-content:last-child {
    margin-bottom: 0;
}

.red-color {
    color: #E50F0F;
}

.to-pay span {
    font-weight: bold;
}
.product-payment-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
    /* border-bottom: 1px solid #e0e0e0; */
    padding-bottom: 4px;
    color: #424242;
    text-transform: capitalize;
    font-size: 12px;
}
.product-payment-content:last-child {
    margin-bottom: 0;
}
.product-payment-detail {
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 4%;
    color: var(--orange-color);
    font-weight: bold;
}
input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--orange-color);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}