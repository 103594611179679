.discount-container {
    position: relative;
    display: flex;
    padding: 10px;
    overflow: hidden;
    margin-top: 20px;
}

.discount-img-container {
    position: relative;
}

.discount-img {
    width: 100%;
    height: 110px;
    object-fit: cover;
    background: url(../../assets/images/item_image.png) center no-repeat;
    background-size: cover;
    border-radius: 6px;
}

.overlay {
    position: absolute;
    bottom: 4px;
    height: 35px;
    width: 100%;
    background-color: var(--black-color);
    border-radius: 0 0 6px 6px;
}

.discount-card-desc {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    left: 10px;
}

.discount-card-item-name,
.discount-card-item-price {
}

.discount-card-item-name {
    font-size: 10px;
    font-weight: bold;
    color: var(--orange-color);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin-bottom: 2px;
}

.discount-card-item-price {
    font-size: 9px;
    color: var(--white-color);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.merchant-detail-discount-card-container {
    height: 135px;
}

.merchant-detail-discount-card-desc {
    bottom: 30px;
}