.filter-container {
    display: flex;
    justify-content: space-between;
    width: 500px;
    padding: 10px 20px;
    background-color: var(--gray-2-color);
    z-index: 2;
}

.scrolled {
    position: fixed;
    width: 500px;
    top: 0px;
    -webkit-animation: moveDown .2s ease-out forwards;
}

.space {

}

.filter-white-space {
    display: flex;
    height: 80px;
}

.filter-by-district-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.filter-text {
    font-size: 11px;
    color: var(--gray-1-color);
    margin-bottom: 8px;
}

.button-filter-container {
    display: flex;
    width: 60%;
    padding: 0;
    align-items: flex-end;
}

.button-filter,
.button-filter-active {
    margin-right: 10px;
    width: 50%;
    height: 35px;
    font-weight: bold;
    background-color: var(--white-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.button-filter {
    color: var(--gray-1-color);
    border-bottom: 5px solid var(--gray-1-color);
}

.button-filter-active {
    color: var(--orange-color);
    border-bottom: 5px solid var(--orange-color);
}

.button-filter:last-child,
.button-filter-active:last-child {
    margin-right: 0;
}

.button-filter:focus {
    font-weight: bold;
    color: var(--orange-color);
    border-bottom: 5px solid var(--orange-color);
}

@keyframes moveDown
{ 
  from { 
      top: -60px;
    }

  to { 
      top: 0px;
    }
}

@keyframes moveUp
{ 
  from { 
      top: 0px;
    }

  to { 
      top: -60px;
    }
}

@media screen and (max-width: 500px) {
    .filter-container {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .button-filter,
    .button-filter-active {
        font-size: 12px;
    }
}

