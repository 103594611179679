/* .bottomnav-container {
    position: fixed;
    width: 990px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 999;
}

.bottomnav-ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;
    justify-content: space-around;
}

.bottomnav-a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #424242;
}

.bottomnav-icons {
    display: block;
    width: 25px;
}

.active {
    color: #FFC00E;
    font-weight: bold;
}

.bottom-space {
    height: 85px;
}

@media screen and (max-width: 1024px) {
    .bottomnav-container {
        width: 95%;
    }
}

@media screen and (max-width: 320px) {
    .bottomnav-container {
        width: 95%;
    }

    .bottomnav-a {
        font-size: 10px;
    }
} */

.bottomnav-wrapper {
    position: fixed;
    width: 500px;
    bottom: 0px;
    padding: 10px 0;
    z-index: 2;
}

.bottomnav-container {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 3px 16px var(--gray-1-color);
    border-radius: 10px;
    margin: 0 10px;
    padding: 0 40px;
    background-color: var(--white-color);
}

.bottomnav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
    text-decoration: none;
    color: var(--black-color);
}

.active {
    color: var(--orange-color);
}

.bottom-space {
    height: 60px;
    background-color: transparent;
}

.gray-bottom-space {
    height: 85px;
    background-color: var(--gray-2-color);
}

@media screen and (max-width: 500px) {
    .bottomnav-wrapper {
        width: 100%;
    }
}