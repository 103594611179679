.start-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 30vh;
    padding: 0 30px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 16px var(--gray-1-color);
}

.start-container span {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
}

.start-container label {
    font-size: 12px;
    margin-bottom: 5px;
}

.start-container input {
    height: 30px;
    padding: 5px;
    border: 1px solid var(--gray-1-color);
    border-radius: 5px;
}

.start-container input:focus {
    outline: none;
}

.start-container button {
    height: 40px;
    border: none;
    background-color: var(--orange-color);
    color: var(--white-color);
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.start-container button:active {
    opacity: 80%;
}