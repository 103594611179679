.merchant-list-container {

}

#scrollbar::-webkit-scrollbar {
    width: 7px;
}

#scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100px;
}

#scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
}

#scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.merchant-list-count {
    text-align: right;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    padding: 20px 20px 0 20px;
}

.component-card-loading{
    overflow: hidden;
}

/* Card title */
.component-title {
    padding: 8px;
    font-size: 22px;
    font-weight: 700;
}

.component-title.loading {
    height: 1rem;
    margin: 1rem;
    border-radius: 3px;
}

.component-description {
    padding: 8px;
    font-size: 16px;
}

.component-description.loading {
    height: 1rem;    
    margin: 1rem;
    border-radius: 3px;
}

/* The loading Class */
.loading {
    width: 200px;
    position: relative;
    background-color: #e2e2e2;    
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.merchant-list-card {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    margin-bottom: 0px;
    cursor: pointer;
    width: 100%;
    padding: 20px 10px;
    background-color: var(--white-color);
}

.merchant-list-card:last-child {
    margin-bottom: 0;
}

.merchant-list-card img {
    vertical-align: middle;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.img-container {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.merchant-list-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    background-size: cover;
    /* display: none; */
}
.merchant-list-img-status {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background-color: black;
    opacity: 0.6;
    background-size: cover;
    /* display: none; */
}

.merchant-list-img-merchant {
    width: 110px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/merchant-image.png) center no-repeat;
    background-size: cover;
    /* display: none; */
}

.merchant-list-description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.merchant-list-name,
.merchant-list-desc,
.border {
    margin-bottom: 8px;
}

.merchant-list-name {
    font-size: 14px;
    flex-wrap: wrap;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.merchant-list-desc {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.border {
    width: 100%;
    height: 3px;
    background-color: var(--gray-2-color);
}

.merchant-list-detail-container {
    display: flex;
    flex-direction: row;
}

.merchant-list-detail {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.merchant-list-detail-container span {
    margin-right: 15px;
    font-size: 11px;
}

.merchant-list-detail-icon {
    position: relative;
    bottom: 1.2px;
    font-size: 14px;
}

.merchant-menu-container {
    /* padding: 10px */
}

.merchant-menu-list-card {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    /* padding: 10px; */
    cursor: pointer;
    width: 100%;
}

.merchant-menu-category {
    padding: 4px;
    border-bottom: 3px solid var(--gray-2-color);
}

.merchant-menu-category-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-color);
}

.merchant-menu-list {
    position: relative;
    display: flex;
}

.merchant-menu-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    background: url(../../assets/images/item_image.png) center no-repeat;
    background-size: cover;
}

.merchant-menu-list-detail {
    display: flex;
    flex-direction: column;
}

.merchant-menu-list-detail-name,
.merchant-menu-list-detail-discounted {
    margin-bottom: 15px;
}

.merchant-list-detail-icon {
    vertical-align: middle;
}

.merchant-menu-list-detail-name {
    font-size: 14px;
    font-weight: bold;
    color: var(--black-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.merchant-menu-list-detail-discounted {
    font-size: 12px;
    color: #E50F0F;
}

.merchant-menu-list-detail-price {
    font-size: 12px;
    margin-bottom: 10px;
    color: var(--black-color);
}

.merchant-menu-add-button button {
    width: 50px;
    height: 25px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.merchant-menu-counter {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
}

/* .merchant-menu-counter button {
    width: 35px;
    height: 20px;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
} */

.empty-stock-text {
    font-size: 12px;
    color: var(--orange-color);
}

.button-counter {
    width: 35px;
    height: 20px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
    font-size: 12px;
}

.button-add-item {
    width: 45px;
    height: 20px;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.menu-counted {
    margin: 0 9px;
    font-size: 12px;
    color: var(--orange-color);
}

.item-detail-container {
    background-color: var(--gray-2-color);
    min-height: 100vh;
}

.item-detail-main-content {
    padding: 10px;
    /* background-color: var(--gray-2-color);
    min-height: 100vh; */
}

.item-detail-img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    background-color: var(--gray-2-color);
}

.item-detail-img-container img {
    width: 100%;
    height: 100%;
}

.item-detail-desc {
    position: relative;
    display: flex;
    flex-direction: column;
}

.item-detail-name,
.item-detail-discounted {
    margin-bottom: 6px;
}

.item-detail-name {
    font-weight: bold;
    font-size: 12px;
    width: 85%;
}

.item-detail-price {
    margin-bottom: 30px;
    font-size: 12px;
}

.item-detail-description {
    font-size: 12px;
}

.item-detail-discounted {
    color: #E50F0F;
    font-size: 12px;
}

.item-detail-notes {
    display: flex;
    margin: 30px 0;
    width: 100%;
}

.item-detail-notes input {
    width: 100%;
    height: 50px;
    padding: 0 7px;
    border: 1px solid var(--gray-1-color);
    border-radius: 5px;
    color: var(--black-color);
    font-size: 14px;
}

.item-detail-notes input:focus {
    outline: none;
}

.item-detail-counter {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 1;
}

.item-detail-counter button {
    width: 60px;
    height: 23px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--orange-color);
    border-radius: 4px 10px;
    color: var(--orange-color);
    cursor: pointer;
}

.button-counter-icon {
    position: relative;
    top: 1px;
    font-size: 12px;
}

.item-detail-counted {
    margin: 0 9px;
    font-size: 16px;
    color: var(--orange-color);
}

@media (min-width:769px) and (max-width:1024px) {
    .component-description.loading, .component-title.loading {
        width: 300px;
    }
}