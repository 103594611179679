.promo-badge {
    margin: 0 5px;
    padding: 4px 5px;
    background-color: var(--orange-color);
    color: var(--white-color);
    border-radius: 50px;
    font-size: 7px;
    font-weight: bold;
    letter-spacing: .3px;
}

.cart-badge {
    position: absolute;
    background-color: red;
    width: 20px;
    height: 20px;
    border: 2px solid var(--white-color);
    border-radius: 50%;
    top: -10px;
    right: 20px;
}

.cart-sum {
    position: absolute;
    top: -7.5px;
    right: 24.5px;
    font-size: 13px;
}

#halal-badge {
    width: 10px;
}

@media screen and (max-width: 425px) {

    .promo-badge {
        font-size: 8px;
        padding: 4px 5px;
    }
}