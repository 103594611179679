.banner-container {
    padding: 20px 0;
    margin-top: -20px;
}

.banner-image-container { 
    width: 100%;
    cursor: pointer;
}

.banner-image-container img {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/promo_image.svg);
    background-size: cover;
}

.promo-category-container {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;
    margin-bottom: 15px;
}

.promo-category-name-container {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
}

.promo-category-name {
    font-size: 14px;
    text-transform: capitalize;
    color: var(--black-color);
    font-weight: bold;
}

.promo-category-name-container a {
    text-decoration: none;
    color: var(--white-color);
    text-transform: capitalize;
    font-weight: bold;
    font-size: 12px;
    background-color: #FF6000;
    border-radius: 50px;
    padding: 2px 5px;
}

.promo-discount-container {
    position: relative;
}

.promo-discount-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    background-color: var(--gray-1-color);
}

.promo-discount-top-text {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 8px;
}

.promo-discount-top-name {
    text-shadow: 2px 2px 5px var(--black-color);;
    font-size: 13px;
    font-weight: bold;
    color: var(--white-color);
}

.promo-discount-bottom-text {
    display: flex;
    flex-direction: column;
    margin: 0 7px;
}

.promo-discount-bottom-price {
    font-size: 13px;
    font-weight: bold;
    color: var(--gray-1-color);
}

.promo-discount-bottom-name {
    font-size: 13px;
    color: var(--gray-1-color);
}

.promo-detail-container {
    padding: 20px 0px;
}

.promo-detail-image-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin: 0 10px 20px 10px;
    overflow: hidden;
    align-items: center;
}

.promo-detail-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.promo-detail-text-container {
    display: flex;
    margin: 0 15px;
    flex-direction: column;
}

.promo-detail-title,
.promo-detail-desc {
    color: var(--black-color);
}

.promo-detail-title {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 10px;
}

.promo-detail-desc {
    font-size: 12px;
}

.promo-detail-date {
    margin: 50px 0;
    border-bottom: 3px solid var(--gray-1-color);
    padding-bottom: 5px;
}

.promo-detail-date span {
    color: var(--black-color);
    font-size: 12px;
}

.promo-detail-merchant-list-container {
    padding: 10px;
}

.promo-detail-merchant-list-text {
    text-transform: capitalize;
    font-weight: bold;
    color: var(--black-color);
}

.promo-detail-merchant-list-card {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.promo-detail-merchant-list-card img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 20px;
}

.promo-detail-merchant-name {
    text-transform: capitalize;
    color: var(--black-color);
    font-weight: bold;
}